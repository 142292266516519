import React, { useState } from "react";
import Layout from "@coursemaker/gatsby-theme-coursemaker/src/components/layout";
import {Link, navigate} from "gatsby";
import FastAPIClient from "../../../../auth/fast_api_auth";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const RegistrationPage = ({ pageContext }) => {
  const schoolExternalId = pageContext.school.external_id;
  let client = new FastAPIClient(schoolExternalId);
  const [error, setError] = useState(false);
  const [registerForm, setRegisterForm] = useState({ full_name: '', email: '', password: '' });

  const validateForm = () => {
    return registerForm.email.length > 0 && registerForm.password.length > 6;
  }

  const handleSubmit = (event) => {
      event.preventDefault();
      setError(false);
      if (!validateForm()) {
          setError(true);
          toast.error("Please complete the form. Passwords should be at least 6 characters");
          return;
      }
      client.register(registerForm.email, registerForm.password, registerForm.full_name)
          .then(() => {
              toast.success("Registration complete!");
              navigate('/')
          }).catch((err => {
            toast.error(err.response?.data?.detail);
        }));
    }

    let schoolThemeStyle = pageContext.school?.schoolThemeStyle;
    if (!schoolThemeStyle) {
        schoolThemeStyle = {
            primaryColor: 'blue',
            secondaryColor: 'blue',
        };
    }

  return (
    <Layout pageContext={pageContext} schoolThemeStyle={schoolThemeStyle}>
      <section className="py-16 bg-indigo-100 md:py-24">
				<div className="container">
					<div className="mb-8 text-center">
						<h2>Register</h2>
						<p className="text-xl font-light text-gray-500 md:text-3xl">Create a new account</p>
					</div>

					<div className="mx-auto form-wrapper md:w-7/12 lg:w-4/12">
						<form className="px-8 py-10 bg-white rounded-lg shadow-lg md:px-10 md:py-12" onSubmit={handleSubmit}>
							<div className="input-fields space-y-6">
								<div className="input-field">
			            <label className="block mb-2 font-semibold text-gray-700" htmlFor="name">
				            Full Name
                  </label>
                  <input
                      className="w-full px-3 py-2 font-light bg-gray-100 border border-gray-300 rounded"
                      autoFocus
                      id="name"
                      type="text"
                      value={registerForm.full_name}
                      placeholder="Joe Bloggs"
                      onChange={(e) => setRegisterForm({...registerForm, full_name: e.target.value })}
                    />
                </div>
								<div className="input-field">
			            <label className="block mb-2 font-semibold text-gray-700" htmlFor="email">
				            Email
                  </label>
                  <input
                      className="w-full px-3 py-2 font-light bg-gray-100 border border-gray-300 rounded"
                      id="email"
                      type="email"
                      value={registerForm.email}
                      placeholder="name@example.com"
                      onChange={(e) => setRegisterForm({...registerForm, email: e.target.value })}
                    />
                </div>
								<div className="input-field">
                <label className="block mb-2 font-semibold text-gray-700" htmlFor="password">
				            Password
                  </label>
                  <input
                      className="w-full px-3 py-2 font-light bg-gray-100 border border-gray-300 rounded"
                      id="password"
                      type="password"
                      value={registerForm.password}
                      onChange={(e) => setRegisterForm({...registerForm, password: e.target.value })}
                    />
                </div>
								<input
									type="submit"
									value="Sign up"
									className={`block w-full text-center btn btn-lg text-white bg-blue-500`}
								/>
							</div>
						</form>
						<p className="mt-5 text-center text-gray-500">
							<span>Already have an account? </span>
							<Link sx={{
                                color: "primary_blue",
                            }} to="/login">Login</Link>
						</p>
					</div>
				</div>
			</section>
    </Layout>
  );
};

export default RegistrationPage;

